import React, { useState, useEffect } from "react";
import styled from "styled-components";
import timeBlockService from "../../../utils/api/v1/timeblockService";
import nurseService from "../../../utils/api/v1/nurseService";
import {
  groupTimeBlocks,
  groupBlockedTimeBlocks,
} from "../../../utils/timeblocks/group";
import { differenceWith } from "lodash";

// Types
import {
  TimeBlock,
  BlockedTimeBlock,
  GroupedTimeBlocks,
} from "../../../utils/interfaces/Timeblock";

// Components
import LoadingError from "../../../components/Loaders/LoadingError";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import InnerContainer from "../../../components/Containers/InnerContainer";
import OutterContainer from "../../../components/Containers/OutterContainer";
import DisplayTimeBlock from "../../../components/Timeblocks/DisplayTimeBlock";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddBlockedTimeblock from "../../../components/Timeblocks/AddBlockedTimeblock";
import DisplayEditableBlockedTimeBlock from "../../../components/Timeblocks/DisplayEditableBlockedTimeBlock";

const SAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

const TitleWrapper = styled.h1`
  margin: 20px 0px;
`;

interface ScheduleInterface {
  id: string;
}

const BlockSchedule = (props: ScheduleInterface): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [activeTimeBlocks, setActiveTimeBlocks] = useState<GroupedTimeBlocks>(
    {}
  );
  const [rawActiveTimeBlocks, setRawActiveTimeBlocks] = useState<TimeBlock[]>(
    []
  );
  const [blockedTimeBlocks, setBlockedTimeBlocks] = useState<GroupedTimeBlocks>(
    {}
  );
  const [rawBlockedTimeBlocks, setRawBlockedTimeBlocks] = useState<
    BlockedTimeBlock[]
  >([]);
  const [allTimeBlocks, setAllTimeBlocks] = useState<TimeBlock[]>([]);
  const [showAddBlocked, setShowAddBlocked] = useState<boolean>(false);
  const [nurse, setNurse] = useState<any>({names: "", last_names: ""});

  const fetchTimeblocks = async (): Promise<void> => {
    setLoading(true);
    try {
      const [actTbReq, allTbReq] = await Promise.all([
        timeBlockService.fetchNurseTimeBlocks(props.id),
        timeBlockService.fetchAllTimeBlocks({ nurse_id: props.id }),
      ]);
      const actTbs = actTbReq.data.data;
      const allTbs = allTbReq.data.data;

      setActiveTimeBlocks(
        groupTimeBlocks(actTbs.timeblocks) as GroupedTimeBlocks
      );
      setRawActiveTimeBlocks(actTbs.timeblocks);
      setAllTimeBlocks(allTbs);
      setBlockedTimeBlocks(groupBlockedTimeBlocks(actTbs.blocked));
      setRawBlockedTimeBlocks(actTbs.blocked);
    } catch (err: any) {
      /* Find the typing for axios errors and replace the any */
      setError(err.response.data.error);
      console.log(err);
    }
    setLoading(false);
  };

  const fetchNurse = async (): Promise<void> => {
    setLoading(true);
    try {
      const req = await nurseService.fetchNurseProfile(props.id);
      setNurse(req.data);
    } catch (err) {
      console.log(err);
      setError(err.response.data.error);
    }
    setLoading(false);
  };

  const updateActiveTimeBlocks = (newTimeblocks: TimeBlock[]) => {
    const oldTimeblocks = [...rawActiveTimeBlocks];
    const timeblocks = oldTimeblocks.concat(newTimeblocks);
    setRawActiveTimeBlocks(timeblocks);
    setActiveTimeBlocks(groupTimeBlocks(timeblocks) as GroupedTimeBlocks);
  };

  const updateBlockedTimeBlocks = (newTimeblocks: BlockedTimeBlock[]) => {
    setRawBlockedTimeBlocks(newTimeblocks);
    setBlockedTimeBlocks(groupBlockedTimeBlocks(newTimeblocks));
  };

  const deleteBlockedTimeBlocks = (deletedTimeBlocks: TimeBlock[]): void => {
    const oldBlockedTbs = [...rawBlockedTimeBlocks];
    const newTimeblocks = differenceWith(
      oldBlockedTbs,
      deletedTimeBlocks,
      (arrVal: BlockedTimeBlock, othVal: TimeBlock) => {
        if (arrVal.id === othVal.id) {
          return true;
        } else {
          return false;
        }
      }
    );
    setRawBlockedTimeBlocks(newTimeblocks);
    setBlockedTimeBlocks(groupBlockedTimeBlocks(newTimeblocks));
  };

  useEffect((): void => {
    fetchTimeblocks();
    fetchNurse();
  }, []);

  return (
    <PrivateRoute>
      <h1>{`${nurse.names} ${nurse.last_names}`}</h1>
      <OutterContainer>
        <InnerContainer backgroundColor="white" padding="20px 40px">
          <LoadingError loading={loading} error={error} />
          {!loading && !error && (
            <>
              <TitleWrapper>
                Horarios actuales
              </TitleWrapper>
              <DisplayTimeBlock
                timeblocks={activeTimeBlocks}
              />
              <TitleWrapper>
                Horarios Bloqueados
                {!showAddBlocked && (
                  <SAddCircleIcon
                    onClick={() => {
                      setShowAddBlocked(true);
                    }}
                  />
                )}
              </TitleWrapper>
              {showAddBlocked && (
                <AddBlockedTimeblock
                  onCancel={() => {
                    setShowAddBlocked(false);
                  }}
                  timeblocks={allTimeBlocks}
                  updateTimeblocks={updateBlockedTimeBlocks}
                  id={props.id}
                  type="nurse"
                />
              )}
              <DisplayEditableBlockedTimeBlock
                timeblocks={blockedTimeBlocks}
                deleteBlocks={deleteBlockedTimeBlocks}
                id={props.id}
                type="nurse"
              />
            </>
          )}
        </InnerContainer>
      </OutterContainer>
    </PrivateRoute>
  );
};

export default BlockSchedule;
