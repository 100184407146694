import React from "react";
import { monthNames, daysToSpanish } from "../../utils/dates/names";

// Typings
import { TimeBlock } from "../../utils/interfaces/Timeblock";
import Block from "./Block";

// Components
import { DayContainer, BlockContainer } from "./Containers"

interface Props {
  timeblocks: {
    [date: string]: TimeBlock[][];
  };
  deleteBlocks: (deletedTimeBlocks: TimeBlock[]) => void;
  id: string;
  type: "lab" | "nurse";
}

const DisplayEditableBlockedTimeBlock = (props: Props): JSX.Element => {
  const displayDay = (date: string, dayData: any): JSX.Element => {
    const [year, month, day] = date.split('-', 3);
    const dayName = daysToSpanish[dayData[0][0].day];
    const dateName = `${dayName} ${day} de ${monthNames[month - 1]} de ${year}`;

    const blocks = dayData.map((block): JSX.Element => {
      return <Block data={block} key={block[0].id} onDelete={props.deleteBlocks} type="blocked" id={props.id} owner={props.type} />
    })

    return (
      <DayContainer key={date} >
        <h4>
          {dateName}
        </h4>
        <BlockContainer>
          {blocks}
        </BlockContainer>
      </DayContainer>
    )

  }

  const displayAllBlocks = (): JSX.Element[] => {
    const daysArray = [];

    for (const [key, value] of Object.entries(props.timeblocks)) {
      daysArray.push(displayDay(key, value))
    }

    return daysArray;
  }

  return (
    <>
      {displayAllBlocks()}
    </>
  )
}

export default DisplayEditableBlockedTimeBlock;
